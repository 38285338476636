import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import VerticalNavbar from "../components/VerticalNavbar";
import Profile from "../components/Profile.tsx";
import { PropagateLoader } from "react-spinners";
import Favorites from "../components/Favorites";

const ProfileView = () => {
    const [loading, setLoading] = useState(false);
    const [widthVal, setWidthVal] = useState("250px");
    const widthValNumber = parseInt(widthVal);
    const widthValNumberLast = widthValNumber + 10;

    const location = useLocation();

    function displayPropagateLoader() {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <PropagateLoader />
            </div>
        );
    }

    if (loading) {
        return displayPropagateLoader();
    }

    return (
        <div className="full-height">
            <div className="h-100">
                <VerticalNavbar widthVal={widthVal} setWidthVal={setWidthVal} />
                <div
                    style={{
                        padding: `10px 100px 10px ${widthValNumberLast+100}px`,
                        transition: "padding-left 0.4s ease",
                        overflow: "auto",
                        height: "100%",
                    }}
                >
                    <div className="h-full overflow-y-auto">
                        {location.pathname === "/profile" && (
                            <Profile />
                        )}
                        {location.pathname === "/profile/favorites" && <Favorites />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileView;