const About = () => {
    return (
        <div>
            <h2>Hakkında</h2>
            <p>
                Leagle Asistanı ile belgeler yükleyerek veya yüklemeden sorgulama yapabilirsiniz. Bir veya daha fazla belge yüklendiğinde, Leagle Asistanı sorgunuza, belge(ler)inize dayalı olarak yanıt verecek ve belgelerdeki referanslara bağlı yanıtlar üretecektir.
            </p>
            <h3>Leagle Asistanını Kullanma İpuçları</h3>
            <ul>
                <li>Sorgular açık, açıklayıcı ve spesifik olmalıdır. Bir sorguya biraz yönlendirme sağlamak büyük fark yaratabilir (örneğin, "Şirket adi hisse senedi sahipleri" demek yerine "adi hisse senedi sahipleri" demek veya "yazılı hukuki görüş" demek yerine sadece "hukuki görüş" demek).</li>
                <li>Özellikle analitik veya öznel bir görev içeriyorsa, karmaşık sorguları daha basit bileşenlere ayırın.</li>
                <li>Sorgunuza bazı arka plan bilgileri ve bağlam sağlayın (örneğin, hukuki bir yetki alanı).</li>
            </ul>
            <h3>Önemli Hatırlatmalar</h3>
            <ul>
                <li>Yüklenen belgeler PDF (.pdf) veya Word (.docx) dosyaları olmalıdır.</li>
                <li>Çıktıları her zaman doğruluk ve tamlık açısından çift kontrol edin. Yanıtlar halüsinasyonlar içerebilir. Özellikle belirli davalar, kanunlar, şirket dosyaları veya Leagle'ın eğitim verilerindeki boşlukları ortaya çıkarabilecek diğer kaynaklar hakkında soru sorarken dikkatli olun.</li>
                <li>Belgeler yüklendiğinde, metin soldan sağa doğru, sayfa boyunca yorumlanır. Bu, Leagle'ın çok sütunlu sayfaları, dikey metni veya görüntüleri tanıyamadığı anlamına gelir.</li>
                <li>Yanıtlar, sorgu dilindeki küçük değişikliklere ve aynı sorgunun birden fazla gönderimine göre değişebilir. Farklı yanıtları keşfetmek için sorgunuzu rafine etmeyi düşünün.</li>
                <li>Asistan, bir düzeltme aracı olarak tasarlanmamıştır.</li>
            </ul>
        </div>
    );
}

export default About;