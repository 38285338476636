import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, onSnapshot} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyC3XPSTN2P8YcPEGisEAFtfffmlQdHbHCQ",
    authDomain: "muvekkil-5f24a.firebaseapp.com",
    projectId: "muvekkil-5f24a",
    storageBucket: "muvekkil-5f24a.appspot.com",
    messagingSenderId: "684981400758",
    appId: "1:684981400758:web:813a56caa5b234aee72217",
    measurementId: "G-EBRH1Q7S9W"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

const storage = getStorage(app);

export { app, auth, db, storage };
