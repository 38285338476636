import React from 'react';
import { marked } from 'marked';
import About from "./About";
import { PropagateLoader } from 'react-spinners';

const AssistantResponse = ({ isLoading, response }) => {

    function displayResponse() {
        return (
            <div style={{overflowY:'auto'}}>
                <div dangerouslySetInnerHTML={{ __html: marked.parse(response, { gfm: true }) }} />
            </div>
        );
    }

    function displayPropagateLoader(){
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}>
                <PropagateLoader />
            </div>
        )
    }

    return (
        response ? displayResponse() : isLoading ? displayPropagateLoader() : <About/>
    );
};

export default AssistantResponse;
