import React, { useEffect, useState } from 'react';
import { XCircle } from 'lucide-react';

const PaymentFailedView = () => {
    const [countdown, setCountdown] = useState(5);

    const handleRedirect = () => {
        window.location.href = 'https://leagleapp.com';
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount <= 1) {
                    clearInterval(timer);
                    handleRedirect();
                }
                return prevCount - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="w-full max-w-md px-4">
                <div className="text-center mx-auto">
                    <div className="mb-4">
                        <XCircle className="h-12 w-12 mx-auto" style={{color: 'red'}}/>
                    </div>

                    <h1 className="text-xl font-medium text-gray-900 mb-3">
                        Ödeme Başarısız!
                    </h1>

                    <div className="mb-2">
                        <p className="text-sm text-gray-600">
                            Ödeme alınırken bir hata ile karşılaşıldı. Lütfen daha sonra tekrar deneyiniz.
                        </p>
                    </div>

                    <div className="mb-4">
                        <p className="text-sm text-gray-500">
                            {countdown} saniye içinde ana sayfaya yönlendirileceksiniz...
                        </p>
                    </div>

                    <button
                        onClick={handleRedirect}
                        style={{backgroundColor: '#6B1A28'}}
                        className="inline-flex items-center justify-center py-2 px-6 text-sm font-medium rounded-md text-white hover:opacity-90 transition-colors"
                    >
                        Ana Sayfa'ya Devam Et
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentFailedView;