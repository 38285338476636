import axiosClient from "../client/LeagleClient";


const promptsService = {

    async getPrompts() {
        const url = `/chat/prompts`;
        try{
            const response = await axiosClient.get(url);
            return response.data;
        } catch (e) {
            return []
        }

    },

    async savePrompt(prompt) {
        const url = `/chat/prompts`;
        await axiosClient.post(url, {
            prompt: prompt
        })
    }
};

export default promptsService;
