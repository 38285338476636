import React, {useState, useRef} from "react";
import uploadImage from "../assets/upload-icon.png";
import { CButton, CCloseButton } from "@coreui/react";
import { Toast } from "primereact/toast";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const MAX_FILE_COUNT = 5;

const FileInput = ({ uploadedFiles, setUploadedFiles }) => {
    const [dragOver, setDragOver] = useState(false);
    const toast = useRef(null);

    const showNegativeToast = (message) => {
        if (toast.current) {
            toast.current.show({
                severity: "error",
                summary: "Hatalı!",
                detail: message,
                life: 3000,
            });
        } else {
            console.error("Toast ref is null");
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragOver(false);
        const files = Array.from(event.dataTransfer.files);
        setUploadedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const invalidFiles = [];
        const validFiles = files.filter(file => {
            if (file.size <= MAX_FILE_SIZE) {
                return true;
            } else {
                invalidFiles.push(file);
                return false;
            }
        });

        if (invalidFiles.length > 0) {
            const invalidFileNames = invalidFiles.map(file => file.name).join(', ');
            showNegativeToast(`Yüklenen bazı dosyalar aşırı büyük:\n${invalidFileNames}\nDosya boyutu 10MB'ı geçemez.`);
        }
        setUploadedFiles(prevFiles => [...prevFiles, ...validFiles]);
    };

    const handleRemoveFile = (index) => {
        setUploadedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };

    return(
        <div
            className="d-flex flex-column flex-grow-1"
            style={{
                backgroundColor: dragOver ? '#f8f9fa' : '#f3f4f7',
                minHeight: 0,
            }}
            onDragOver={uploadedFiles.length === 0 ? handleDragOver : null}
            onDragLeave={uploadedFiles.length === 0 ? handleDragLeave : null}
            onDrop={uploadedFiles.length === 0 ? handleDrop : null}
            onClick={uploadedFiles.length === 0 ? () => document.getElementById('fileInput').click() : null}
        >
            <Toast ref={toast} />
            <input
                type="file"
                id="fileInput"
                multiple
                accept=".pdf, .docx"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            {uploadedFiles.length === 0 ? (
                <div className="d-flex flex-column align-items-center justify-content-center h-100 p-3" style={{cursor: 'pointer'}}>
                    <img src={uploadImage} alt="upload" style={{ width: '50px', marginBottom: '10px' }} />
                    <p>Dosya yüklemek için tıklayın veya sürükleyin.</p>
                    <p>PDF ve Word dosyaları kabul edilir.</p>
                    <p>(maksimum 10MB, en fazla 5 dosya)</p>
                </div>
            ) : (
                <div className="d-flex flex-column h-100" >
                    <div className="d-flex justify-content-between align-items-center p-2">
                        <h4 className="m-0">Dokümanlar ({uploadedFiles.length}/{MAX_FILE_COUNT})</h4>
                        {uploadedFiles.length < MAX_FILE_COUNT && (
                            <CButton color="dark" variant="outline" size="lg" onClick={() => document.getElementById('fileInput').click()}>Dosya yükle</CButton>
                        )}
                    </div>
                    <ul className="list-unstyled overflow-auto flex-grow-1 m-0 p-0">
                        {uploadedFiles.map((file, index) => (
                            <li key={index} className="d-flex justify-content-start align-items-center p-2 border-bottom">
                                <CCloseButton onClick={() => handleRemoveFile(index)}></CCloseButton>
                                <span className="ms-3">{file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FileInput;