import {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {Navigate} from "react-router-dom";
import {SubscriptionTier} from "../model/enum/subscriptionTier.ts";

const HomeRedirect = () => {
    const { user, subscriptionTier } = useContext(AuthContext);


    if (!user) {
        return <Navigate to="/login" />;
    }

    if (subscriptionTier === SubscriptionTier.BASIC ) {
        return <Navigate to="/dokuman-arama-page" />;
    }

    if (subscriptionTier === SubscriptionTier.PRO) {
        return <Navigate to="/yapay-zeka-ile-arama" />;
    }

    if (subscriptionTier === SubscriptionTier.EXEMPT) {
        return <Navigate to="/yapay-zeka-ile-arama" />;
    }

    return <Navigate to="/login" />;
};

export default HomeRedirect
