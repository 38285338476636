import {CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/react";
import React, {useEffect, useState} from "react";
import promptsService from "../service/PromptsService";
import { auth } from "../firebaseConfig";


const AssistantSearchInput = ({prompt, setPrompt, task, setTask}) => {
    const [previousPrompts, setPreviousPrompts] = useState([
        `Detaylı ve profesyonel bir kira sözleşmesi oluştur. 
Sözleşme şu bilgileri içermelidir: Kiraya verenin adresi '123 Ana Cadde, İstanbul, Türkiye' ve kiracının adresi '456 Karaağaç Sokak, Ankara, Türkiye' olarak belirtilecektir. 
Sözleşme başlangıç tarihi 1 Ocak 2025'tir ve kira süresi beş yıl olarak belirlenmiştir, sözleşme otomatik olarak 31 Aralık 2029 tarihinde sona erecektir. 
Kiralanan yer yalnızca 'Otomobil Galerisi (Oto Galeri)' olarak kullanılacak, bu amacın dışına çıkılması durumunda sözleşme ihlali sayılacaktır. 
Aylık kira bedeli 10.000 TL olup, KDV ve diğer yasal yükümlülükler kiracı tarafından ödenecektir. Kiracı, sözleşme başlangıcında 20.000 TL iade edilebilir bir depozito ödeyecektir.
Sözleşmenin yenilenmesi için her iki taraf, kira süresi sona ermeden en az 60 gün önce yazılı bildirimde bulunacaktır. Uyuşmazlıkların çözümünde Bakırköy Mahkemeleri ve İcra Daireleri yetkili olacaktır. 
Sözleşme; bakım ve onarım sorumlulukları, ödeme şartları, erken fesih koşulları, ihlal durumunda cezalar ve tazminatlar, fesih ve teslim süreci gibi maddeleri içermelidir.`,
        `Sana bir kira sözleşmesi veriyorum. Sözleşmede şu bilgileri güncelle:
1. Kira süresini 12 ay olarak güncelle.
2. Kiracının tüm fatura ödemelerinden sorumlu olduğunu belirten bir madde ekle.
3. Kiranın geç ödenmesi durumunda uygulanacak cezaları detaylandıran bir bölüm ekle.
4. Sözleşmenin, mülkün ticari amaçlarla kullanılamayacağını açıkça belirt.
5. Her iki taraf için de 30 günlük bir ihbar süresi içeren fesih maddesi yoksa ekle, varsa değişiklik yapma.`
    ]);
    const userId = auth.currentUser.uid

    useEffect(() => {
        const fetchPrompts = async () => {
            try{
                const prompts = await promptsService.getPrompts();
                setPreviousPrompts(prev => [...prev, ...prompts]);
            } catch (e) {
                console.error(e)
            }
        };
        fetchPrompts();
    }, [userId]);

    const handlePromptSelect = (prompt) => {
        setPrompt(prompt)
    };

    const handleTaskSelect = (task) => {
        setTask(task)
    };

    const handleSavePrompt = () => {
        if (prompt.trim()) {
            promptsService.savePrompt(prompt);
            setPreviousPrompts([...previousPrompts, prompt]);
        }
    };

    return(
        <div className="d-flex flex-column flex-grow-1" style={{minHeight: 0, height:'35%'}}>
            <div className="mb-3">
                <CDropdown>
                    <CDropdownToggle color="primary" size="lg" style={{fontSize: '1.3rem'}}>
                        {task === 'contract_analysis' ? 'Sözleşme Analizi' : 'Sözleşme Taslağı'}
                    </CDropdownToggle>
                    <CDropdownMenu>
                        {/* <CDropdownItem onClick={() => handleTaskSelect('answer_general_question')} style={{fontSize: '1.3rem'}}>Genel Hukuki Soru</CDropdownItem> */}
                        <CDropdownItem onClick={() => handleTaskSelect('contract_analysis')} style={{fontSize: '1.3rem'}}>Sözleşme Analizi</CDropdownItem>
                        <CDropdownItem onClick={() => handleTaskSelect('draft_contract')} style={{fontSize: '1.3rem'}}>Sözleşme Taslağı</CDropdownItem>
                        {/* <CDropdownItem onClick={() => handleTaskSelect('case_summary')} style={{fontSize: '1.3rem'}}>Dava Özeti</CDropdownItem> */}
                        {/* <CDropdownItem onClick={() => handleTaskSelect('legislation_analysis')} style={{fontSize: '1.3rem'}}>Mevzuat Analizi</CDropdownItem> */}
                    </CDropdownMenu>
                </CDropdown>
            </div>

            <div style={{height:'100%', paddingBottom:'2%'}}>
                 <textarea
                     className="assistant-textarea"
                     value={prompt}
                     onChange={(e) => setPrompt(e.target.value)}
                     placeholder="Sorgunuzu buraya girin..."
                     style={{
                         backgroundColor: "#f3f4f7",
                         width: '100%',
                         border: 'none',
                         padding: '2%',
                         boxSizing: 'border-box',
                         resize: 'none',
                         overflowY: 'auto',
                         height:'100%',
                     }}
                 />
            </div>

            <div className="d-flex mt-2 justify-content-start">
                <CDropdown>
                    <CDropdownToggle color="primary" size="lg">Önceki komutlarım</CDropdownToggle>
                    <CDropdownMenu>
                        {previousPrompts.map((prompt, index) => (
                            <CDropdownItem key={index} onClick={() => handlePromptSelect(prompt)} style={{fontSize: '1.3rem'}}>
                                {prompt.length > 30 ? prompt.substring(0, 30) + '...' : prompt}
                            </CDropdownItem>
                        ))}
                    </CDropdownMenu>
                </CDropdown>
                <CButton className="ms-3" color="dark" variant="outline" size="lg" onClick={handleSavePrompt}>Komutu kaydet</CButton>

            </div>
        </div>
    );
};

export default AssistantSearchInput;