import axiosClient from "../client/LeagleClient";


const identityService = {

    async getCustomToken() {
        const url = `/identity/signin`;
        const response = await axiosClient.post(url)
        return response.data.token;
    }
};

export default identityService;
