import React, { useEffect, useState } from 'react';
import { CheckCircle } from 'lucide-react';

const PaymentSuccessView = () => {
    const [countdown, setCountdown] = useState(5);

    const handleRedirect = () => {
        window.location.href = window.location.origin;
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCount) => {
                if (prevCount <= 1) {
                    clearInterval(timer);
                    handleRedirect();
                }
                return prevCount - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="min-h-screen flex items-center justify-center">
            <div className="w-full max-w-md px-4"> {/* Changed max-w-lg to max-w-md */}
                <div className="text-center mx-auto"> {/* Added mx-auto */}
                    <div className="mb-4">
                        <CheckCircle className="h-12 w-12 text-green-500 mx-auto" style={{color: 'green'}}/> {/* Increased from h-8 w-8 to h-12 w-12 */}
                    </div>

                    <h1 className="text-xl font-medium text-gray-900 mb-3">
                        Ödeme Başarılı!
                    </h1>

                    <div className="mb-2">
                        <p className="text-sm text-gray-600">
                            Şifre sıfırlama bağlantınız e-posta adresinize gönderilmiştir. Lütfen gelen kutunuzu kontrol ediniz.
                        </p>
                    </div>

                    <div className="mb-4">
                        <p className="text-sm text-gray-500">
                            {countdown} saniye içinde ana sayfaya yönlendirileceksiniz...
                        </p>
                    </div>

                    <button
                        onClick={handleRedirect}
                        style={{backgroundColor: '#6B1A28'}}
                        className="inline-flex items-center justify-center py-2 px-6 text-sm font-medium rounded-md text-white hover:opacity-90 transition-colors"
                    >
                        Ana Sayfa'ya Devam Et
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccessView;