import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
    CForm,
    CFormLabel,
    CRow,
    CCol,
} from "@coreui/react";
import Input from "./ui/Input/Input";
import Button2 from "./ui/Button2/Button2";
import default_profile_image from "../assets/images/default_profile_image.png";
import { UserProfileData } from "../model/feModal/userProfileData";

interface UserProfileDataProps {
    profileData: UserProfileData;
    user: any;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleUpdateProfileData: (e: React.FormEvent<HTMLFormElement>) => void;
}

const UserProfileDataModal: React.FC<UserProfileDataProps> = ({
                                                                  profileData,
                                                                  user,
                                                                  handleChange,
                                                                  handleUpdateProfileData
                                                              }) => {
    const formFields = [
        {
            id: 'email',
            label: 'Email',
            value: profileData.email,
            readonly: true
        },
        {
            id: 'organization',
            label: 'Organizasyon',
            value: profileData.organization,
            readonly: false
        },
        {
            id: 'occupation',
            label: 'Rol',
            value: profileData.occupation,
            readonly: false
        },
        {
            id: 'phoneNumber',
            label: 'Telefon Numarası',
            value: profileData.phoneNumber,
            readonly: false
        }
    ];

    return (
        <CCard className="border-0 shadow-sm">
            <CCardHeader className="bg-white border-bottom-0 py-4">
                <h2 className="text-dark m-0 fs-4">Profil Bilgileri</h2>
            </CCardHeader>
            <CCardBody className="px-4 py-5">
                {/* Profile Image Section */}
                <div className="text-center mb-4">
                    <div className="position-relative d-inline-block">
                        <img
                            src={user?.photoURL || default_profile_image}
                            alt="Profile"
                            className="rounded-circle"
                            style={{
                                width: '120px',
                                height: '120px',
                                objectFit: 'cover',
                                border: '4px solid #f8f9fa'
                            }}
                        />
                    </div>
                    {profileData.name && (
                        <h3 className="mt-3 mb-4 fs-5 text-dark">
                            {profileData.name}
                        </h3>
                    )}
                </div>

                {/* Profile Form */}
                <CForm onSubmit={handleUpdateProfileData} className="mt-4">
                    <div className="d-flex flex-column gap-4">
                        {formFields.map((field) => (
                            <div key={field.id} className="form-group">
                                <CRow className="align-items-center">
                                    <CCol xs={12} sm={4}>
                                        <CFormLabel
                                            htmlFor={field.id}
                                            className="text-secondary"
                                        >
                                            {field.label}:
                                        </CFormLabel>
                                    </CCol>
                                    <CCol xs={12} sm={8}>
                                        <Input
                                            type="text"
                                            id={field.id}
                                            name={field.id}
                                            value={field.value}
                                            onChange={handleChange}
                                            readOnly={field.readonly}
                                            className={`form-control ${field.readonly ? 'bg-light' : ''}`}
                                            style={{
                                                borderRadius: '8px',
                                                padding: '0.75rem 1rem',
                                                border: '1px solid #dee2e6',
                                                width: '100%',
                                                transition: 'all 0.2s ease-in-out'
                                            }}
                                            children={undefined}
                                        />
                                    </CCol>
                                </CRow>
                            </div>
                        ))}

                        <div className="mt-4">
                            <Button2
                                type="submit"
                                color="primary"
                                className="w-100 py-3"
                                style={{
                                    borderRadius: '8px',
                                    fontWeight: '600',
                                    transition: 'all 0.2s ease-in-out',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}
                            >
                                Profili Güncelle
                            </Button2>
                        </div>
                    </div>
                </CForm>
            </CCardBody>
        </CCard>
    );
};

export default UserProfileDataModal;