import { React, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import logo from "../assets/images/leagle_logo_white_background_.2.jpg";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import Button from "./ui/Button/Button";
import Input from "./ui/Input/Input";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordError("");
    try {
      await login(email, password);
      navigate("/");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setPasswordError("Hatalı şifre girdiniz. Lütfen tekrar deneyiniz.");
      }
      if (error.code === "auth/user-not-found") {
        setPasswordError("Bu e-posta adresine kayıtlı bir kullanıcı bulunamadı.");
      }
    }
  };

  return (
      <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm onSubmit={handleSubmit}>
                      <h1>Giriş Yap</h1>
                      <p className="text-body-secondary">Hesabına giriş yap</p>
                      <div className="d-flex mb-2">
                        <CInputGroupText
                            style={{
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                        >
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            autoComplete="email"
                            style={{
                              width: "100%",
                              margin: "0",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                        />
                      </div>
                      <div className="d-flex mb-3">
                        <CInputGroupText
                            style={{
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                        >
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <Input
                            value={password}
                            style={{
                              width: "100%",
                              margin: "0",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            placeholder="Parola"
                            autoComplete="current-password"
                        />
                      </div>
                      {passwordError && (
                          <div className="text-danger mb-3">
                            {passwordError}
                          </div>
                      )}
                      <CRow>
                        <CCol xs={6}>
                          <Button type="submit">
                            Giriş Yap
                          </Button>
                        </CCol>
                        <CCol xs={6} className="text-right">
                          <Link to="/forgot-password">
                            <CButton size="lg" color="link">
                              Şifremi Unuttum
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard
                    className="text-white bg-primary py-5"
                    style={{ width: "100%" }}
                >
                  <CCardBody className="text-center">
                    <div className="login-logo-container">
                      <img src={logo} alt="Logo" />
                    </div>
                    <div>
                      <h2>Üye Ol</h2>
                      <p>
                        Leagle'a hoş geldin! Benzersiz bir hukuk deneyimine hazır
                        ol!
                      </p>
                      <Link style={{margin:'auto'}} className="nav-link w-40andfull" to="https://leagleapp.com/tr/price">
                        Şimdi Üye Ol!
                      </Link>
                    </div>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
  );
};

export default Login;